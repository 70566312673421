<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card-header class="mb-3">
      <b-row>
        <b-col> <i class="icon-menu mr-1"></i>Manual Bookings</b-col>
      </b-row>
    </b-card-header>
    <b-card>
      <div>
        <b-row>
          <div class="col-3">
            <img
              class="w-100"
              src="http://admin.movies.wanasatime.com/img/Noimage.jpg"
              alt=""
            />
          </div>
          <div class="col-9">
            <div>Movie Name</div>
            <hr />
            <b-row>
              <p class="col-md-3 mb-0"><strong>Cinema:</strong></p>
              <p class="col-md-9 mb-0">Cinema Name</p>
            </b-row>
            <hr />
            <b-row>
              <p class="col-md-3 mb-0"><strong>Show Time:</strong></p>
              <p class="col-md-9 mb-0">12:00 PM</p>
            </b-row>
            <hr />
            <b-row>
              <p class="col-md-3 mb-0"><strong>Show Date:</strong></p>
              <p class="col-md-9 mb-0">12-05-2022</p>
            </b-row>
            <hr />
            <b-row>
              <p class="col-md-3 mb-0"><strong>Booked Seats:</strong></p>
              <p class="col-md-9 mb-0">10</p>
            </b-row>
            <hr />
            <b-row>
              <p class="col-md-3 mb-0"><strong>Total Cost</strong></p>
              <p class="col-md-9 mb-0">100</p>
            </b-row>
            <hr />
          </div>
        </b-row>
      </div>
      <div class="mt-3">
        <b-row>
          <div class="col-md-4">
            <label for="name"><strong>Name:</strong></label>
            <b-form-input
              id="name"
              type="text"
              placeholder="Name"
              v-model="seatSelection.name"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="email"><strong>Email:</strong></label>
            <b-form-input
              id="email"
              type="text"
              placeholder="Email"
              v-model="seatSelection.email"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label><strong>Mobile:</strong></label>
            <b-row>
              <b-col sm="3"
                ><b-form-input
                  type="text"
                  placeholder="Prefix"
                  v-model="seatSelection.prefix"
                ></b-form-input
              ></b-col>
              <b-col class="pl-0"
                ><b-form-input
                  type="text"
                  placeholder="Mobile"
                  v-model="seatSelection.mobile"
                ></b-form-input
              ></b-col>
            </b-row>
          </div>
        </b-row>

        <!-- </b-row> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";
import OrganizationService from "@/services/OrganizationService";
import ReportService from "@/services/ReportService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "ManualSeatBookings",
  components: {
    Event,
    cSwitch,
  },
  watch: {
    "bookingsData.radioOption": function (val) {
      if (val == "byOrderID") {
        this.bookingsData.tabIndex = 1;
      } else {
        this.bookingsData.tabIndex = 0;
      }
    },
  },
  data: function () {
    return {
      seatSelection: {
        name: "",
        email: "",
        prefix: "",
        mobile: "",
      },
    };
  },
  beforeMount() {},
  mounted() {},
  methods: {
    // Saif
  },
};
</script>

<style></style>
